import React, { useState, useEffect, useCallback } from 'react'
import { TextField, InputAdornment, CircularProgress } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import LambdaFetch from '../functions/FetchFromLambda'
import debounce from 'lodash.debounce'
import { throttle } from 'lodash'

const match = require('autosuggest-highlight/match')

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
}))

function ServerSideAutoComplete (props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(null)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const debouncedLookup = useCallback(
    debounce((inputVal, secondayParams) => {
      throttleFunction(inputVal, secondayParams)
    }, 500),
    []
  )

  const throttleFunction = throttle(function (input,second) {
    autocompleteSearch(input, second)
      }, 500);

  const autocompleteSearch = async (inputVal, secondaryParams) => {
    try {
      setLoading(true)
      let fetchString = JSON.stringify({
        lookup: inputVal,
        ...secondaryParams
      })

      const resp = await LambdaFetch(
        props.apiResource,
        'post',
        props.accessToken,
        fetchString,
        '',
        props.credentials
      )

      if (!resp.success) return null
      const suggestions = resp.data.suggestions.map(hit => {
        return {
          id: hit.id,
          hit: hit.suggestion,
          ...hit
        }
      })
      setLoading(false)

      setOptions(suggestions)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    handleChange(value, inputValue)
  }, [value, inputValue])

  const handleChange = async (value, inputValue) => {
    props.handleChange(value ? value.id : '', inputValue)
    debouncedLookup(inputValue, props.secondaryParams)
  }
  let autoHighlight = true
  if(props.dontAutoHighlight) autoHighlight = false
  return (
    <Autocomplete
      id={props.id}
      style={{ width: props.width }}
      getOptionLabel={
        props.customGetOptionLabel
          ? props.customGetOptionLabel
          : option => `${option.hit}`
      }
      options={options}
      loading={loading}
      autoComplete
      autoSelect
      autoHighlight={autoHighlight}
      openOnFocus={props.dontOpenOnFocus ? false : true}
      disabled={props.disabled}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      onOpen={e => {
        autocompleteSearch('', props.secondaryParams)
      }}
      renderInput={params => (
        <TextField
          {...params}
          required={props.required}
          label={props.label}
          value={props.value}
          margin='dense'
          variant={props.variant ? props.variant : 'outlined'}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position='start'>
                  <div></div>
                </InputAdornment>
              </>
            )
          }}
          helperText={props.helper}
        />
      )}
      renderOption={
        props.customRenderOption
          ? props.customRenderOption
          : option => {
              return <div style={{ fontSize: '14px' }}>{option.hit}</div>
            }
      }
    />
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 1000,
    marginTop: 0,
    left: 0,
    right: 0,
    top: 46,
    borderRadius: '3px',
    overflowY: 'auto',
    maxHeight: '205px',
    overflow: 'scroll'
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`
  },
  inputRoot: {
    flexWrap: 'wrap'
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1
  },
  divider: {
    height: theme.spacing(2)
  },
  closeIcon: {
    position: 'absolute',
    width: '15px',
    height: '15px',
    bottom: -15,
    right: 10,
    cursor: 'pointer',
    border: '1px solid gray',
    borderRadius: '10px'
  },
  spinner: {
    marginLeft: '10px',
    color: '#545454',
    position: 'absolute',
    right: 10,
    bottom: -15
  }
})

export default ServerSideAutoComplete
