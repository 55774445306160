import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Slide
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import { printManagerCols } from '../constants/PrintManagerCols'
import LoadingButton from '../components/LoadingButton'

import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import { smallScreenWidth } from '../constants/AppConstants'




const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '1rem'
  }
}))



const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function PrintManager (props) {
  const [state, setstate] = useState({
    printManagerData: [],
    downloadId: null
  })
  const [deleteData, setDeleteData] = useState({
    isOpen: false,
    isLoading: false,
    files: []
  })
  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  const fetch = async () => {
      try {
        const resp = await LambdaFetch(
          'reports',
          'post',
          credentials.user.accessToken,
          JSON.stringify({
            action: 'print-manager-read'
          }),
          '',
          credentials
        )
        setstate({ ...state, printManagerData: resp.data.printManagerData })
      } catch (e) {
        console.log(e)
      }
  }
  useEffect(() => {
    fetch()
  }, [])

  const getFile = async (key, name, id) => {
    setstate({ ...state, downloadId: id })
    const myKey = `${key}.part_00000`
    try {
      const resp = await LambdaFetch(
        'get-s3-object',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          key: myKey,
          name: name,
          time: 60 * 5 
        }),
        '',
        credentials
      )

      if (resp.success) {
        const downloadUrl = resp.data.url
        window.open(downloadUrl, '_blank')
      }
      setstate({ ...state, downloadId: null })
    } catch (e) {
      console.log(e)
      setstate({ ...state, downloadId: null })
    }
  }

  const confirmDelete = async (info) => {

    const deleteIndeices = info.data.reduce((acc,cur) => {
      return [...acc,cur.dataIndex]
    },[])

    const deleteFiles = state.printManagerData.slice().filter((row,index) => {
      return deleteIndeices.includes(index)
    }).map(row => Object.assign({}, {id: row.id, document_link: row.document_link, name: row.event_name}))

    console.log(deleteFiles)
    try {


      const resp = await LambdaFetch(
        'get-s3-object',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'delete',
          files: deleteFiles
        }),
        'Successfully deleted file(s)',
        credentials
      )
      console.log(resp)
      // setDeleteData({...deleteData, isLoading: false, isOpen: false, files: []})
      fetch()
    } catch (e) {
      console.log(e)
    }

    // setDeleteData({...deleteData, files: deleteFiles, isOpen: true})
  }
  // const deletFiles = async (currentFiles) => {
  //   setDeleteData({...deleteData, isLoading: true})
  //   try {
  //     await LambdaFetch(
  //       'get-s3-object',
  //       'post',
  //       credentials.user.accessToken,
  //       JSON.stringify({
  //         action: 'delete',
  //         files: currentFiles
  //       }),
  //       'Successfully deleted file(s)',
  //       credentials
  //     )
  //     setDeleteData({...deleteData, isLoading: false, isOpen: false, files: []})
  //     fetch()
  //   } catch (e) {
  //   }
  // }
  const options = getTableOptions({cols: printManagerCols, fileName: `Print_Manager`})
options['selectableRows'] = 'multiple'
options['onRowsDelete'] = confirmDelete
options['responsive'] = "scroll"
  const printTableData = state.printManagerData.map(row => {
    row.link = (
      <div>
        {state.downloadId === row.id ? (
          <div style={{ paddingLeft: '1rem' }}>
            <CircularProgress
              className='loading-circle'
              disableShrink
              size={16}
            />
          </div>
        ) : (
          <div
            className='editLink'
            onClick={() =>
              getFile(row.document_link, `${row.event_name}`, row.id)
            }
          >
            download
          </div>
        )}
      </div>
    )
    return row
  })

  return (
    <>
    {/* <Dialog
				open={deleteData.isOpen}
				TransitionComponent={Transition}
				maxWidth="xl"
				scroll="body"
				keepMounted
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
			<DialogTitle id="title">{'Confirm Delete'}</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
           Are you sure you want to delete the following file(s)?
           </DialogContentText>

           <List dense style={{overflowY: 'scroll', maxHeight: '250px'}}> 
           {deleteData.files.map(f => {
            return <ListItem>
              <ListItemText primary={f.name} />
              </ListItem>
          })}
       
  </List>
        
				</DialogContent>
				<DialogActions>
					<Button style={{marginRight: '0.5rem'}} onClick={() => setDeleteData({...deleteData, isOpen: false})} variant="text">
						Close
					</Button>


        

          <LoadingButton
                label='Confirm'
                isLoading={deleteData.isLoading}
                color='primaryVLButton'
                fn={() => deleteFiles()}
              />
				</DialogActions>
			</Dialog> */}
    <div style={{ margin: props.fetchInitialData.credentials.appWidth < smallScreenWidth ? '1rem auto' : '1rem' }}>
      <Grid container>
        <Grid item xs={12} style={{ position: 'relative'}}>
          <TableMui
            cols={printManagerCols}
            data={printTableData}
            options={options}
            title=''
          />
        </Grid>
      </Grid>
    </div>
    </>
  )
}
