import { dateFormat } from '../utils/formatter'

export const requisitionInquiryCols = [
  {
    name: 'LINE_NBR',
    label: 'Line',
    options: {
      filter: true,
      sort: true
    }
  },

  {
    name: 'ITEM',
    label: 'Item',
    options: {
      filter: true,
      sort: true
    }
  },

  {
    name: 'DESCRIPTION',
    label: 'Description',
    options: {
      filter: true,
      sort: true
    }
  },

  {
    name: 'ITEM_TYPE',
    label: 'Type',
    options: {
      filter: true,
      sort: true
    }
  },

  {
    name: 'ENTERED_OUM',
    label: 'UOM',
    options: {
      filter: true,
      sort: true
    }
  },

  {
    name: 'QUANTITY',
    label: 'Qty',
    options: {
      filter: true,
      sort: true
    }
  },

  {
    name: 'STATUS',
    label: 'Status',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'REQ_DEL_DATE',
    label: 'Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'DELIVER_TO',
    label: 'Deliver To',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'PO_LINK',
    label: 'PO Source',
    options: {
      filter: true,
      sort: true
    }
  },
]
