import React, { useState, useEffect } from 'react'
import LambdaFetch from '../functions/FetchFromLambda'
import MUIDataTable from 'mui-datatables'
import { debounce } from 'throttle-debounce'
import {Skeleton} from '@material-ui/lab'

import { TextField, MenuItem } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { testCols } from '../constants/GlCols'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import CustomFooter from '../utils/CustomDataTableFooter'
const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          padding: '3px 6px'
        }
      }
    }
  })

export function ServerSideTable (props) {
  const [state, setstate] = useState({
    isLoading: false,
    page: 0,
    count: -1,
    rowsPerPage: props.rowsPerPage
  })

  const [tableOptions, setTableOptions] = useState({
    responsive: 'standard'
  })

  const [data, setData] = useState([[`Loading Data...`]])

  // useEffect(() => {
  //   async function fetchMyAPI() {
  //     fetchData(state.page, null, state.rowsPerPage, null, {});
  //   }
  //   fetchMyAPI(props.fetchInitialData.credentials);
  // }, [state.rowsPerPage]);

  useEffect(() => {
    async function fetchMyAPI () {
      fetchData(0, null, state.rowsPerPage, null, {})
    }
    fetchMyAPI(props.fetchInitialData.credentials)
  }, [props.params.chartName])

  const fetchData = async (
    curPage,
    sortOrder,
    numEntries,
    searchVal,
    filters
  ) => {
    const skel = Array(Math.min(numEntries, 20)).fill(
      testCols.reduce((acc, el) => {
        acc[el.name] = <Skeleton height={20} />
        return acc
      }, {})
    )

    const sort = sortOrder ? `${sortOrder.name} ${sortOrder.direction}` : -1
    setstate({ ...state, isLoading: true })
    setData(skel)

    const resp = await LambdaFetch(
      props.apiResource,
      'post',
      props.fetchInitialData.credentials.user.accessToken,
      JSON.stringify({
        action: props.action,
        params: props.params,
        columns: props.tableColumns,
        page: curPage,
        sortOrder: sort,
        entriesPerPage: numEntries,
        searchVal,
        filters
      }),
      '',
      props.fetchInitialData.credentials
    )

    setData(resp.data.tableData)
    setstate({
      ...state,
      count: resp.data.count[0].COUNT,
      page: curPage,
      isLoading: false
    })
  }

  const handleTableChange = (action, tableState) => {
    const filters = tableState.filterList.reduce((acc, cur, index) => {
      acc[tableState.columns[index]['name']] = cur[0] ? cur[0] : null
      return acc
    }, {})

    switch (action) {
      case 'changePage':
        fetchData(
          tableState.page,
          tableState.sortOrder,
          state.rowsPerPage,
          tableState.searchText,
          filters
        )
        break
      case 'sort':
        fetchData(
          tableState.page,
          tableState.sortOrder,
          state.rowsPerPage,
          tableState.searchText,
          filters
        )
        break
      case 'search':
        debounceFetchTableData(tableState, state.rowsPerPage, filters)
        break
      case 'filterChange':
        debounceFetchTableData(tableState, state.rowsPerPage, filters)
        break
    }
  }

  const debounceFetchTableData = debounce(
    1000,
    false,
    (tableState, numRows, filters) => {
      const { page, sortOrder, searchText } = tableState
      fetchData(page, sortOrder, numRows, searchText, filters)
    }
  )
  const { page, count } = state

  const options = {
    setRowProps: (row, dataIndex, rowIndex) => {
      if (rowIndex % 2 === 0) {
        return {
          style: { background: '#F7F7F7' }
        }
      }
    },
    responsive: tableOptions.responsive,
    tableBodyMaxHeight: '620px',
    selectableRows: 'none',
    filterType: 'textField',
    filter: true,
    rowHover: false,
    print: false,
    displaySelectToolbar: false,
    rowsPerPage: state.rowsPerPage,
    rowsPerPageOptions: [state.rowsPerPage],
    serverSide: true,
    count: count,
    page,
    onTableChange: handleTableChange,
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={state.count}
          page={state.page}
          rowsPerPage={state.rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
        />
      )
    }
  }

  return (
    <div>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={props.tableTitle}
          data={data}
          columns={props.tableColumns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  )
}
