import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  MenuItem,
  Paper,
  TextField
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { glChartCols } from '../constants/GlCols'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingCircle from '../components/common/LoadingCircle'
import TableMui from '../components/TableMui'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'
import getTableOptions from '../constants/TableOptions'
import { smallScreenWidth } from '../constants/AppConstants'
import LoadingButton from '../components/LoadingButton'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  }
}))

export default function Gl (props) {
  const [state, setstate] = useState({
    glSelect: '',
    glOptions: [],
    chartData: null,
    fetched: false,
    isLoading: false
  })

  const classes = useStyles()

  const { credentials } = props.fetchInitialData
  useEffect(() => {
    async function fetchMyAPI () {
      fetchData()
    }
    fetchMyAPI()
  }, [])

  const fetch = async id => {
    try {
      setstate({ ...state, isLoading: true })
      const resp = await LambdaFetch(
        'gl-form',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'chart-account-read',
          chartName: id
        }),
        '',
        credentials
      )
      setstate({
        ...state,
        chartData: resp.data.chartAccounts,
        isLoading: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!state.glSelect) return null
    fetch(state.glSelect)
  }

  const fetchData = async () => {
    try {
      const resp = await LambdaFetch(
        'gl-form',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'charts-read'
        }),
        '',
        credentials
      )
      setstate({ ...state, glOptions: resp.data.glCharts, fetched: true })
    } catch (e) {
      console.log(e)
    }
  }

  const handleChange = e => {
    e.preventDefault()
    setstate({ ...state, glSelect: e.target.value })
  }

  const options = getTableOptions({cols: glChartCols, fileName: `GL_Account_${state.glSelect}`});

  options['responsive'] = 'scrollMaxHeight'

  if (!state.fetched) {
    return <LoadingCircle />
  }
  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        GL Accounts
      </Typography>

      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={2}
            >
              <Grid item>
                <SimpleAutoComplete
                  name='glSelect'
                  width='300px'
                  required={true}
                  variant='standard'
                  label='Chart'
                  value={state.glSelect}
                  onChange={val => {
                    setstate({ ...state, glSelect: val ? val.CHART_NAME : '' })
                  }}
                  options={state.glOptions}
                  getOptionLabel={val => val.DESCRIPTION}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>
        {state.chartData && (
          <Grid
            item
            xs={12}
            style={{ position: 'relative', marginTop: '1rem' }}
          >
            <TableMui
              cols={glChartCols}
              data={state.chartData}
              options={options}
              title=''
            />
          </Grid>
        )}

      </div>
    </div>
  )
}
