import { currencyFormat, dateFormat } from '../utils/formatter'

export const itemCols = [
  {
    name: 'ITEM_NBR',
    label: 'Item Number',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Description',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'PURCHASE_DATE',
    label: 'Purchase Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'ITEM_QUANTITY',
    label: 'Qty',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ITEM_COST_BASE',
    label: 'Cost',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  }
]

export const bookCols = [
  {
    name: 'BOOK',
    label: 'Book',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'BOOK_STATUS',
    label: 'Status',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'LIFE',
    label: 'Life',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'LIFE_REM',
    label: 'Remaining',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'INSRV_DATE',
    label: 'Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'METHOD',
    label: 'Method',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'CONVENTION',
    label: 'Convention',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'CONVENTION_TXT',
    label: 'Convention Desc',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'BOOK_BASIS',
    label: 'Basis',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'DEPR_YTD',
    label: 'YTD',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'DEPR_LTD',
    label: 'LTD',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'BOOK_VALUE',
    label: 'Book',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  }
  
]

export const am80Cols = [
  {
    name: 'TRANS_DATE',
    label: 'Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'BOOK',
    label: 'Book',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true,
      display: false
    }
  },
  {
    name: 'ASSET_LINK',
    label: 'Asset',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ASSET_PROCESS',
    label: 'Process',
    options: {
      filter: true,
      sort: true
    }
  },

  {
    name: 'REFERENCE',
    label: 'Reference',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCT_UNIT',
    label: 'Acct Unit',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCOUNT',
    label: 'Acct',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SUB_ACCOUNT',
    label: 'Sub Acct',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRANS_AMOUNT',
    label: 'Amount',
    options: {
      setCellProps: value => {
        return {
          style: { textAlign: 'right' }
        }
      },
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  }
]

export const am70Cols = [
  {
    name: 'ASSET_LINK',
    label: 'Asset',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TAG_NBR',
    label: 'Tag',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'PURCHASE_DATE',
    label: 'Purchase Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Desc',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'LEASE_COMPANY',
    label: 'Lease Comp',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'LEASE',
    label: 'Lease',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'LOCATION_NAME',
    label: 'Loc',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DIVISION',
    label: 'Div',
    options: {
      filter: true,
      sort: true
    }
  },

  {
    name: 'ASSET_TYPE',
    label: 'Type',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SUB_TYPE',
    label: 'Sub Type',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ASSET_GROUP',
    label: 'Group',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ITEM_COST_TRAN',
    label: 'Cost',
    options: {
      customBodyRender: currencyFormat,
      setCellProps: value => {
        return {
          style: { textAlign: 'right' }
        }
      },
      filter: true,
      sort: true
    }
  }
]
