import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Typography,
  Grid,
  Paper,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import TableMui from '../components/TableMui'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import { cashBookTransCols } from '../constants/CashCodeCols'
import getTableOptions from '../constants/TableOptions'

import { smallScreenWidth } from '../constants/AppConstants'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import LoadingButton from '../components/LoadingButton'
import { SimpleSelect } from '../functions/SimpleSelect'
import {dateFormat} from '../utils/formatter';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

const defaultHeaders = {
  cashCode: '',
  bankCode: '',
  fromDate: '',
  toDate: ''
}

export default function CashBookTrans (props) {
  const classes = useStyles()
  const { credentials } = props.fetchInitialData
  const [state, setstate] = useState({
    cbTransData: null,
    selectedAsset: '',
    selectedCompany: '',
    assetData: null,
    isLoading: false
  })
  const [headers, setHeaders] = useState(defaultHeaders)
  const [cashCodeSelected, setCashCodeSelected] = React.useState('')
  const [bankCodeSelected, setBankCodeSelected] = React.useState('')
  const [transNbr, setTransNbr] = React.useState('')
  const [searchType, setSearchType] = React.useState('Payment')
  const handleSubmit = e => {
    e.preventDefault()
    if (!cashCodeSelected || !bankCodeSelected) return null
    getCbTrans(cashCodeSelected, bankCodeSelected)
  }
const clearHeader = () => {

}
  const handleSubmitTrans = e => {
    e.preventDefault()
  
    if(!transNbr && !(headers.cashCode && headers.bankCode)) {
      props.fetchInitialData.createSnack('Please enter a Payment or Cash Code AND Payment Type', 'warning', 3000)
      return null
    }

    if(!!transNbr) {
      getCbTransByNbr(transNbr)
    } else {
      getCbTrans(headers.cashCode, headers.bankCode, headers.fromDate, headers.toDate)
    }

  }

  const getCbTrans = async (cash, bank, fromDate , toDate) => {
    try {
      setstate({ ...state, isLoading: true })
      const resp = await LambdaFetch(
        'cash-book',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-cashbook',
          cashCode: cash,
          bankCode: bank,
          fromDate,
          toDate
        }),
        '',
        credentials
      )
      setstate({
        ...state,
        cbTransData: resp.data.cbTrans,
        isLoading: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  const getCbTransByNbr = async nbr => {
    try {
      setstate({ ...state, isLoading: true })
      const resp = await LambdaFetch(
        'cash-book',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-cashbook-by-nbr',
          transNbr: nbr
        }),
        '',
        credentials
      )
      setstate({
        ...state,
        cbTransData: resp.data.cbTrans,
        isLoading: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  const printValue = (dataObj, lookup, description, secondary, secondType) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1

    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal}>
          {`${
            isDate
              ? dateFormat(dataObj[lookup])
              : dataObj[lookup]
          }${
            secondary && dataObj[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? dataObj[secondary]
                    : dateFormat(dataObj[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    )
  }
  const options = getTableOptions({
    cols: cashBookTransCols,
    fileName: `Cash_Book_Trans_${cashCodeSelected}_${bankCodeSelected}`
  })
  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
      Cash Book Trans (CB80)
      </Typography>

      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          {/* <div style={{ marginBottom: '0.5rem' }}>
            <SimpleSelect
              options={[
                { value: 'Payment', label: 'Payment' },
                { value: 'Type', label: 'Type' }
              ]}
              label='Search By'
              onChange={e => {
                setSearchType(e.target.value)
                setBankCodeSelected('')
                setCashCodeSelected('')
              }}
              variant="standard"
              width="150px"
              value={searchType}
            />
          </div> */}



          <form onSubmit={handleSubmitTrans}>
              <Grid
                container
                alignContent='center'
                alignItems='flex-end'

                spacing={2}
              >
                <Grid item>
                  <ServerSideAutoCompletev2
                    disabled={false}
                    id='transNbr'
                    width='200px'
                    label='Payment'
                    key={searchType}
                    accessToken={credentials.user.accessToken}
                    error='No Trans Nbr Found'
                    variant='standard'
                    handleChange={(id, hit) => {
                      setTransNbr(id)
                    }}
                    credentials={credentials}
                    apiResource='cash-book'
                    secondaryParams={{
                      limit: 10,
                      action: 'autocomplete_trans_nbr'
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography style={{verticalAlign: 'bottom'}} variant="body1">OR</Typography>
                  </Grid>
                <Grid item>
                  <ServerSideAutoCompletev2
                    disabled={false}
                    id='cashCode'
                    key={searchType}
                    width='220px'
                    label='Cash Code'
                    accessToken={credentials.user.accessToken}
                    error='No Cash Code Found'
                    variant='standard'
                    handleChange={(id, hit) => {
                      setHeaders({...headers, cashCode: id})
                      // setCashCodeSelected(id)
                    }}
                    credentials={credentials}
                    apiResource='cash-book'
                    secondaryParams={{
                      limit: 10,
                      action: 'autocomplete_cashcode'
                    }}
                  />
                </Grid>
                <Grid item>
                  <ServerSideAutoCompletev2
                    id='bankInstCode'
                    width='220px'
                    key={searchType}
                    label='Payment Type'
                    accessToken={credentials.user.accessToken}
                    error={'No Payment Found'}
                    variant='standard'
                    handleChange={(id, hit) => {
                      setHeaders({...headers, bankCode: id})
                      // setBankCodeSelected(id)
                    }}
                    credentials={credentials}
                    apiResource='cash-book'
                    secondaryParams={{
                      limit: 10,
                      action: 'autocomplete_transactioncode'
                    }}
                  />
                </Grid>
                {[
                {
                  id: 'fromDate',
                  label: 'From Date'
                },
                {
                  id: 'toDate',
                  label: 'To Date'
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <TextField
                      id={field.id}
                      key={clearHeader}
                      style={{"marginBottom": "5px", width: '160px'}}
                      type={'date'}
                      required={false}
                      label={field.label}
                      variant='standard'
                      value={headers.id}
                      fullWidth
                      onChange={(e) => {
                        setHeaders({ ...headers, [field.id]: !!e.target.value ? e.target.value: null })
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <span />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                )
              })}
              </Grid>
          
              <div style={{ marginTop: '1rem' }}>
                <LoadingButton
                  label='Submit'
                  isLoading={state.isLoading}
                  color='primaryVLButton'
                  buttonType='submit'
                />
              </div>
            </form>


          {/* {searchType === 'Payment' ? (
            <form onSubmit={handleSubmitTrans}>
              <Grid
                container
                alignContent='center'
                alignItems='flex-start'
                spacing={2}
              >
                <Grid item>
                  <ServerSideAutoCompletev2
                    disabled={false}
                    required={true}
                    id='transNbr'
                    width='320px'
                    label='Payment'
                    key={searchType}
                    accessToken={credentials.user.accessToken}
                    error='No Trans Nbr Found'
                    variant='standard'
                    handleChange={(id, hit) => {
                      setTransNbr(id)
                    }}
                    credentials={credentials}
                    apiResource='cash-book'
                    secondaryParams={{
                      limit: 10,
                      action: 'autocomplete_trans_nbr'
                    }}
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: '1rem' }}>
                <LoadingButton
                  label='Submit'
                  isLoading={state.isLoading}
                  color='primaryVLButton'
                  buttonType='submit'
                />
              </div>
            </form>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                alignContent='center'
                alignItems='flex-start'
                spacing={2}
              >
                <Grid item>
                  <ServerSideAutoCompletev2
                    disabled={false}
                    required={true}
                    id='cashCode'
                    key={searchType}
                    width='320px'
                    label='Cash Code'
                    accessToken={credentials.user.accessToken}
                    error='No Cash Code Found'
                    variant='standard'
                    handleChange={(id, hit) => {
                      setCashCodeSelected(id)
                    }}
                    credentials={credentials}
                    apiResource='cash-book'
                    secondaryParams={{
                      limit: 10,
                      action: 'autocomplete_cashcode'
                    }}
                  />
                </Grid>
                <Grid item>
                  <ServerSideAutoCompletev2
                    id='bankInstCode'
                    required={true}
                    width='320px'
                    key={searchType}
                    label='Payment Type'
                    accessToken={credentials.user.accessToken}
                    error={'No Payment Found'}
                    variant='standard'
                    handleChange={(id, hit) => {
                      setBankCodeSelected(id)
                    }}
                    credentials={credentials}
                    apiResource='cash-book'
                    secondaryParams={{
                      limit: 10,
                      action: 'autocomplete_transactioncode'
                    }}
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: '1rem' }}>
                <LoadingButton
                  label='Submit'
                  isLoading={state.isLoading}
                  color='primaryVLButton'
                  buttonType='submit'
                />
              </div>
            </form>
          )} */}
        </Paper>
        {!!state.cbTransData && (
          <Grid
            item
            xs={12}
            style={{ position: 'relative', marginTop: '1rem' }}
          >
            <TableMui
              cols={cashBookTransCols}
              data={state.cbTransData}
              options={options}
              title=''
            />
          </Grid>
        )}
      </div>
    </div>
  )
}
