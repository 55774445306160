import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  MenuItem,
  TextField,
  Paper,
  InputAdornment,
  Button
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import {
  glTransCols,
  glActivityCols,
  moreGlTansCols,
  attribCols
} from '../constants/GlCols'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import DialogTable from '../components/DialogTable'
import LoadingCircle from '../components/common/LoadingCircle'
import { smallScreenWidth } from '../constants/AppConstants'
import { ServerSideTable } from '../components/ServerSideTable'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'

const _ = require('lodash')

const serversideEntriesPerPage = 50

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  treeRoot: {
    flexGrow: 1,
    margin: '1rem'
  }
}))

const startAdornment = (
  <>
    <InputAdornment position='start'>
      <div></div>
    </InputAdornment>
  </>
)
const defaultHeaders = {
  fiscalYear: '',
  acctPeriod: '',
  company: '',
  system: '',
  accountUnit: '',
  account: '',
  subAccount: '',
  fromDate: '',
  toDate: ''
}

export default function GlTrans (props) {
  const [state, setstate] = useState({
    glOptions: null,
    options: null,
    isLoading: false,
    data: null,
    fetched: true,
    isServerSide: false
  })
  const [structureData, setStructureData] = useState(null)
  const [headers, setHeaders] = useState(defaultHeaders)
  const [objInfo, setObjInfo] = React.useState(null)
  const [moreInfo, setMoreInfo] = React.useState(null)
  const [attribInfo, setAttribInfo] = React.useState(null)
  const [clearHeader, setClearHeaders] = React.useState(false)

  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  // useEffect(() => {
  //   async function fetchMyAPI () {
  //     fetchData()
  //   }
  //   fetchMyAPI()
  // }, [])

  const formatStructureData = data => {
    const tD = data.map(row => {
      return {
        id: `${row.COMPANY}-${row.ACCT_UNIT}-${row.depth}`,
        name: row.ACCT_UNIT,
        nameDesc: row.DESCRIPTION,
        comp: row.COMPANY,
        acctUnit: row.ACCT_UNIT,
        depth: row.LEVEL_DEPTH,
        children: [],
        compName: row.COMPANY_NAME
      }
    })

    const struc = tD.reduce((acc, cur) => {
      if (!acc[cur.comp])
        acc[cur.comp] = {
          id: `company-${cur.comp}`,
          name: cur.comp,
          nameDesc: cur.compName,
          children: []
        }
      return acc
    }, {})

    for (let i = 0; i < tD.length; i++) {
      let cur = struc[tD[i].comp]
      let curRow = tD[i]

      for (let i = 1; i < curRow.depth; i++) {
        cur = cur.children[cur.children.length - 1]
      }

      cur.children.push(curRow)
    }

    return Object.values(struc)
  }
  const fetchData = async () => {
    try {
      const resp = await LambdaFetch(
        'gl-trans',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-structure'
        }),
        '',
        credentials
      )
      setStructureData(formatStructureData(resp.data.structure))
      setstate({ ...state, fetched: true })
    } catch (e) {
      console.log(e)
    }
  }
  const handleClose = () => {
    setObjInfo(null)
    setMoreInfo(null)
    setAttribInfo(null)
  }
  const handleChange = e => {
    const { name, value } = e.target
    e.preventDefault()
    setHeaders({ ...headers, [name]: value })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    fetchTranData(headers)

    // setstate({ ...state, params: parameters })
  }

  const handleOpenActivity = row => {
    setObjInfo([row])
  }
  const viewAttributes = async objId => {
    const resp = await LambdaFetch(
      'gl-trans',
      'post',
      credentials.user.accessToken,
      JSON.stringify({
        action: 'get-attrib',
        objId
      }),
      '',
      credentials
    )

    setAttribInfo(resp.data.attribData)
  }

  const fetchTranData = async params => {
    setstate({ ...state, isLoading: true })

    try {
      const resp = await LambdaFetch(
        'gl-trans',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-trans',
          entriesPerPage: serversideEntriesPerPage,
          ...params
        }),
        '',
        credentials
      )

      if (resp.data.serverside) {
        setstate({
          ...state,
          isLoading: false,
          isServerSide: resp.data.serverside
        })
        return null
      }
      const glTransData = formatData(resp.data.glTrans)

      setstate({
        ...state,
        data: glTransData,
        isLoading: false,
        isServerSide: resp.data.serverside
      })
    } catch (e) {
      console.log(e)
      setstate({ ...state, isLoading: false })
    }
  }
  const formatData = data => {
    return data.map(row => {
      if (row.ACTIVITY) {
        row['ACTIVITY_LINK'] = (
          <div className="editLink" onClick={() => {
            const win = window.open(`/actrans?id=${row.OBJ_ID}&type=GLT_OBJ_ID`, "_blank");
            win.focus();
        }}>{row.ACTIVITY}</div>
        )
      } else {
        row['ACTIVITY_LINK'] = ''
      }
      let temp = row.ACCOUNT
      row.ACCOUNT = `${temp}${row.SUB_ACCOUNT ? `-${row.SUB_ACCOUNT}` : ''}`
      row['MORE'] = (
        <div className='editLink' onClick={() => setMoreInfo([row])}>
          view
        </div>
      )
      if (row.HAS_GTMXVALUE) {
        row['GTMXVALUE'] = (
          <div
            className='editLink'
            onClick={() => viewAttributes(row.HAS_GTMXVALUE)}
          >
            view
          </div>
        )
      }
      return row
    })
  }

  if (!state.fetched) {
    return <LoadingCircle />
  }

  const options = getTableOptions({ cols: glTransCols, fileName: `GL_Trans` })
  options.downloadOptions.filterOptions.useDisplayedColumnsOnly = false

  const renderTree = nodes => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <div>
          <span>{nodes.name}</span>
          {nodes.nameDesc && (
            <span style={{ color: '#545454' }}>{` - ${nodes.nameDesc}`}</span>
          )}
        </div>
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map(node => renderTree(node))
        : null}
    </TreeItem>
  )

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      {/* <div style={{float: 'right'}}>
<Drawer direction="right" drawerTitle={'Accounting Structure'} buttonLabel="View Acct Structure">

<TreeView
        className={classes.treeRoot}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {structureData.map(comp => renderTree(comp))}
      </TreeView>
  </Drawer>
  </div> */}

      <DialogTable
        isOpen={!!objInfo}
        handleClose={handleClose}
        tableData={objInfo}
        cols={glActivityCols}
        simpleTable={true}
      />
      <DialogTable
        isOpen={!!moreInfo}
        handleClose={handleClose}
        tableData={moreInfo}
        cols={moreGlTansCols}
        simpleTable={true}
      />

      <DialogTable
        isOpen={!!attribInfo}
        handleClose={handleClose}
        tableData={attribInfo}
        cols={attribCols}
        simpleTable={true}
      />
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        GL Trans
      </Typography>
      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={3}
            >
              {[
                {
                  id: 'fiscalYear',
                  dataField: 'FISCAL_YEAR',
                  label: 'Year',
                  dateType: 'number',
                  isRequired: true
                },
                {
                  id: 'acctPeriod',
                  label: 'Account Period',
                  dataField: 'ACCT_PERIOD',
                  dataType: 'number',
                  isRequired: true
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <ServerSideAutoCompletev2
                      disabled={false}
                      required={true}
                      id={field.id}
                      width='125px'
                      label={field.label}
                      key={clearHeader}
                      dontAutoHighlight
                      dontOpenOnFocus
                      accessToken={credentials.user.accessToken}
                      error={`No ${field.label} Found`}
                      variant='standard'
                      handleChange={(id, hit) => {
                        setHeaders({ ...headers, [field.id]: id })
                      }}
                      credentials={credentials}
                      apiResource='gl-trans'
                      secondaryParams={{
                        limit: 10,
                        lookupField: field.dataField,
                        action: 'autocomplete-gl-trans',
                        ...headers
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={3}
            >
              {[
                {
                  id: 'company',
                  dataField: 'COMPANY',
                  label: 'Company',
                  dateType: 'number',
                  isRequired: false
                },
                {
                  id: 'system',
                  label: 'System',
                  dataField: 'SYSTEM',
                  dataType: 'string',
                  isRequired: false
                },
                {
                  id: 'accountUnit',
                  dataField: 'ACCT_UNIT',
                  label: 'Account Unit',
                  dateType: 'number',
                  isRequired: false
                },
                {
                  id: 'account',
                  dataField: 'ACCOUNT',
                  label: 'Account',
                  dateType: 'number',
                  isRequired: false
                },
                {
                  id: 'subAccount',
                  dataField: 'SUB_ACCOUNT',
                  label: 'Sub Account',
                  dateType: 'number',
                  isRequired: false
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <ServerSideAutoCompletev2
                      disabled={false}
                      required={field.isRequired}
                      id={field.id}
                      width='125px'
                      label={field.label}
                      key={clearHeader}
                      dontAutoHighlight
                      dontOpenOnFocus
                      accessToken={credentials.user.accessToken}
                      error={`No ${field.label} Found`}
                      variant='standard'
                      handleChange={(id, hit) => {
                        setHeaders({ ...headers, [field.id]: id })
                      }}
                      credentials={credentials}
                      apiResource='gl-trans'
                      secondaryParams={{
                        limit: 10,
                        lookupField: field.dataField,
                        action: 'autocomplete-gl-trans',
                        ...headers
                      }}
                    />
                  </Grid>
                )
              })}
              {[
                {
                  id: 'fromDate',
                  label: 'From Date'
                },
                {
                  id: 'toDate',
                  label: 'To Date'
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <TextField
                      id={field.id}
                      key={clearHeader}
                      style={{"marginTop": "5px", width: '160px'}}
                      type={'date'}
                      required={false}
                      label={field.label}
                      variant='standard'
                      value={headers.id}
                      fullWidth
                      onChange={(e) => {
                        setHeaders({ ...headers, [field.id]: !!e.target.value ? e.target.value: null })
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <span />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>

            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                disabled={!(headers.fiscalYear && headers.acctPeriod)}
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
              {!!(
                headers.fiscalYear ||
                headers.acctPeriod ||
                headers.company ||
                headers.system ||
                headers.accountUnit ||
                headers.account ||
                headers.subAccount ||
                headers.fromDate ||
                headers.toDate
              ) && (
                <Button
                  onClick={() => {
                    setClearHeaders(!clearHeader)
                    setHeaders(defaultHeaders)
                  }}
                  style={{ marginLeft: '0.5rem' }}
                >
                  {' '}
                  Clear
                </Button>
              )}
            </div>
          </form>
        </Paper>

        {state.isServerSide && (
          <div style={{ marginTop: '1rem ' }}>
            <ServerSideTable
              {...props}
              params={headers}
              action='get-trans-serverside'
              apiResource={'gl-trans'}
              tableTitle={''}
              rowsPerPage={serversideEntriesPerPage}
              tableColumns={glTransCols}
            />
          </div>
        )}

        {state.data && (
          <div style={{ marginTop: '1rem ' }}>
            <TableMui
              cols={glTransCols}
              data={state.data}
              options={options}
              title=''
            />
          </div>
        )}
        {/* {state.params && (
          <div style={{ marginTop: '1rem ' }}>
            <ServerSideTable
              {...props}
              params={state.params}
              apiResource={'gl-trans'}
              tableTitle={'My Test Table'}
              rowsPerPage={50}
              tableColumns={testCols}
            />
          </div>
        )} */}
      </div>
    </div>
  )
}

function BasicSelect (props) {
  return (
    <TextField
      id={props.name}
      name={props.name}
      disabled={props.disabled}
      select
      style={{ width: '125px' }}
      label={props.label}
      onChange={props.handleChange}
      value={props.value}
      InputProps={{
        startAdornment
      }}
    >
      <MenuItem key={-1} value={-1}>
        All
      </MenuItem>
      {props.options.map(p => {
        return (
          <MenuItem key={p} value={p}>
            {p}
          </MenuItem>
        )
      })}
    </TextField>
  )
}
