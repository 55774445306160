import React, { useState } from 'react'
import {
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Slide
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import { icTransCols } from '../constants/ItemCols'
import getTableOptions from '../constants/TableOptions'
import TableMui from './TableMui'
import {dateFormat} from '../utils/formatter';

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

export default function ItemTransDetail (props) {
  const [moreData, setMoreData] = useState(null)

  const classes = useStyles()

  const handleClose = () => {
    setMoreData(null)
  }

  const printValue = (dataObj, lookup, description, secondary, secondType) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1

    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal}>
          {`${
            isDate
              ? dateFormat(dataObj[lookup])
              : dataObj[lookup]
          }${
            secondary && dataObj[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? dataObj[secondary]
                    : dateFormat(dataObj[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    )
  }

  const printBasicValue = (
    dataObj,
    lookup,
    description,
    secondary,
    secondType
  ) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1

    return (
      <div>
        <span className={classes.descriptVal}>
          {`${
            isDate
              ? dateFormat(dataObj[lookup])
              : dataObj[lookup]
          }${
            secondary && dataObj[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? dataObj[secondary]
                    : dateFormat(dataObj[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    )
  }

  const { itemTransData } = props

  const options = getTableOptions({cols: icTransCols, fileName: props.fileName})
  options.downloadOptions.filterOptions.useDisplayedColumnsOnly = false

  return (
    <>
      {moreData && (
        <Dialog
          open={moreData ? true : false}
          TransitionComponent={Transition}
          maxWidth='xl'
          scroll='body'
          keepMounted
        >        
        <div style={{padding: '16px 24px'}}>
        <Typography variant='h6'>{moreData.TYPE_DESC}</Typography>
          <Typography variant='subtitle2'>{dateFormat(moreData.TRANS_DATE)}</Typography>
          </div>

   

          <DialogContent>
            <Grid container spacing={3} direction='row' justify='space-between'>
              <Grid item>
                <Typography variant='subtitle1'>Doc</Typography>
                {printBasicValue(moreData, 'DOCUMENT', 'Document')}
              </Grid>

              <Grid item>
                <Typography variant='subtitle1'>Quantity</Typography>
                {printValue(moreData, 'ORIGINAL_QTY', 'Original Qty')}
                {printValue(moreData, 'TRAN_UOM_MULT', 'UOM Mult')}
                {printValue(moreData, 'STOCK_UOM', 'Stock UOM')}
              </Grid>

              <Grid item>
                <Typography variant='subtitle1'>Reason</Typography>
                {printBasicValue(moreData, 'REASON_CODE', 'Reason Code')}
              </Grid>
              <Grid item>
                <Typography variant='subtitle1'>Cost Info</Typography>

                {printValue(moreData, 'UNIT_COST_CURR', 'Current Cost')}
                {printValue(moreData, 'ADD_UNIT_COST', 'Additional Unit Cost')}
              </Grid>

              <Grid item>
                <Typography variant='subtitle1'>Bin Info</Typography>

                {printValue(moreData, 'BIN', 'Bin')}
                {printValue(moreData, 'FROM_TO_BIN', 'From To Bin')}
                {printValue(moreData, 'FROM_TO_LOC', 'From To Loc')}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <TableMui
        cols={icTransCols}
        data={itemTransData.map(i =>
          Object.assign(i, {
            viewMore: (
              <div className='editLink' onClick={() => setMoreData(i)}>
                view
              </div>
            )
          })
        )}
        options={options}
        title=''
      />
      {/* <Card elevation={props.inverseElevation ? 0 : 2}>
        <CardContent style={{ position: 'relative' }}>
      

          <div style={{ marginTop: '1rem' }}>
            <Grid
              container
              spacing={1}
              direction='row'
              justify='flex-start'
              alignItems='space-evenly'
            >
              <Grid item xs={12}>
                  <Grid
                    container
                    spacing={1}
                    direction='row'
                    justify='space-around'
                  >
                    <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize}>
                      
                      <Typography variant='subtitle1'>Dates</Typography>
                      {printValue(itemTransData, 'TRANS_DATE', 'Trans Date')}
                      {printValue(itemTransData, 'HIST_YEAR', 'Year')}
                      {printValue(itemTransData, 'HIST_PERIOD', 'Period')}
                    </Grid>
                    <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize}>
                      <Typography variant='subtitle1'>System</Typography>
                      {printBasicValue(itemTransData, 'SYSTEM_CD', 'System')}
                    </Grid>

                    <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize}>
                      <Typography variant='subtitle1'>Type</Typography>
                      {printBasicValue(itemTransData, 'TYPE_DESC', 'Type')}
                    </Grid>

                    <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize}>
                      <Typography variant='subtitle1'>Doc</Typography>
                      {printBasicValue(itemTransData, 'DOCUMENT', 'Document')}
                    </Grid>

                    <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize}>
                      <Typography variant='subtitle1'>Quantity</Typography>
                      {printValue(itemTransData, 'QUANTITY', 'Qty')}
                      {printValue(
                        itemTransData,
                        'ORIGINAL_QTY',
                        'Original Qty'
                      )}
                      {printValue(itemTransData, 'TRAN_UOM', 'Tran UOM')}
                      {printValue(itemTransData, 'TRAN_UOM_MULT', 'UOM Mult')}
                      {printValue(itemTransData, 'STOCK_UOM', 'Stock UOM')}
                    </Grid>

                    <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize}>
                      <Typography variant='subtitle1'>Line</Typography>

                      {printBasicValue(itemTransData, 'LINE_NBR', 'Line Number')}
                    </Grid>

                    <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize}>
                      <Typography variant='subtitle1'>Reason</Typography>
                      {printBasicValue(itemTransData, 'REASON_CODE', 'Reason Code')}
                    </Grid>
                    <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize}>
                      <Typography variant='subtitle1'>Cost Info</Typography>

                      {printValue(itemTransData, 'UNIT_COST', 'Cost')}
                      {printValue(
                        itemTransData,
                        'UNIT_COST_CURR',
                        'Current Cost'
                      )}
                      {printValue(
                        itemTransData,
                        'ADD_UNIT_COST',
                        'Additional Unit Cost'
                      )}
                    </Grid>

                    <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize}>
                      <Typography variant='subtitle1'>Bin Info</Typography>

                      {printValue(itemTransData, 'BIN', 'Cost')}
                      {printValue(itemTransData, 'FROM_TO_BIN', 'From To Bin')}
                      {printValue(itemTransData, 'FROM_TO_LOC', 'From To Loc')}
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card> */}
    </>
  )
}
