import React from 'react'
import { withSnackbar } from 'notistack'
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  withStyles,
  TextField,
  Paper,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  InputAdornment,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Tab,
  Tabs,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  TableFooter,
  TableRow,
  TableCell
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MUIDataTable from 'mui-datatables'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import PrintIcon from '@material-ui/icons/Print'
import { AntTabsVert, AntTab } from '../components/common/Tabs'
import LoadingCircle from '../components/common/LoadingCircle'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { customTableSort, formatter } from '../utils/tableCustoms'
import { v4 as uuidv4 } from 'uuid'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const responsive = 'scrollMaxHeight'
const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'left'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8'
  },
  tabsIndicator: {
    backgroundColor: '#E86613'
  },
  selected: {},
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    '&:hover': {
      color: '#E86613',
      opacity: 1
    },
    '&$selected': {
      color: '#E86613'
    },
    '&:focus': {
      color: '#E86613',
      outline: 'none'
    },
    '&:active': {
      outline: 'none'
    }
  },
  primaryVLButton: {
    color: 'white',
    backgroundColor: '#E86613',
    marginLeft: 15,
    width: 'min-content',
    '&:hover': {
      backgroundColor: 'rgba(232, 102, 19, .9)'
    }
  }
})

class Reports extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentReport: null,
      currentReportDropdowns: {},
      reports: [],
      isLoading: true,
      paramOpen: false,
      isSubmitting: false,
      tableCol: null,
      tableData: null,
      tabs: [],
      tabValue: 0,
      openPrintDialog: false,
      reportData: []
    }
    this.runReport = this.runReport.bind(this)
  }

  async componentDidMount () {
    try {
      const resp = await LambdaFetch(
        'reports',
        'post',
        this.props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: 'get-reports'
        }),
        '',
        this.props.fetchInitialData.credentials
      )

      if (resp.success) {
        const data = resp.data
        let tabs = []
        data.reports.forEach(report => {
          if (tabs.indexOf(report.category) === -1) {
            tabs.push(report.category)
          }
        })

        this.setState({
          reports: data.reports,
          isLoading: false,
          tabs: tabs.sort()
        })
      } else {
        this.setState({ isLoading: false }, () => {
          this.createSnack(
            'There was a error grabbing the reports',
            'error',
            3500
          )
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  createSnack (message, type, duration) {
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      variant: type,
      autoHideDuration: duration
    })
  }

  handleClose = () => {
    this.setState({
      currentReport: null,
      currentReportDropdowns: {},
      paramOpen: false
    })
  }
  handleRedirect = href => {
    window.open(href, '_blank')
  }
  getOptions = async (queries, report) => {
    try {
      const resp = await LambdaFetch(
        'reports',
        'post',
        this.props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: 'basic-query',
          ...{
            queries: queries
          }
        }),
        '',
        this.props.fetchInitialData.credentials
      )

      if (resp.success) {
        const data = resp.data
     
        this.setState({
          dropdownData: data.myResults,
          currentReport: report,
          paramOpen: true
        })
        for(let dropdown in data.myResults) {
          this.onTagsChange(null,  data.myResults[dropdown][0], dropdown)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  handleCurrentReport = report => {
    let myQueries = JSON.parse(report.parameters).reduce((acc, field) => {
      if (field.fieldType === 'dynamicList') {
        return [...acc, { id: field.name, query: field.query }]
      } else return acc
    }, [])
    if (myQueries.length > 0) {
      this.getOptions(myQueries, report)
    } else {
      this.setState({ currentReport: report, paramOpen: true })
    }
  }

  runReport = async event => {
    event.preventDefault()
    try {
      const { currentReport } = this.state
      const { user } = this.props.fetchInitialData.credentials

      this.setState({ isSubmitting: true })
      let parameters = JSON.parse(this.state.currentReport.parameters)
      let paramsConditions = []

      let params = parameters.map(p => {
        if (p.fieldType === 'dynamicList') {
          if (
            p.name === 'vendor_id' ||
            p.name === 'user_login_id' ||
            p.name === 'document_type' ||
            p.name === 'role_id'
          ) {
            paramsConditions.push({
              name: p.displayName,
              value:
                this.state[p.name].value === '-1'
                  ? 'All'
                  : this.state[p.name].display
            })
          } else {
            paramsConditions.push({
              name: p.displayName,
              value:
                this.state[p.name].value === '-1'
                  ? 'All'
                  : this.state[p.name].value
            })
          }
          return this.state[p.name].value
        } else if (p.fieldType === 'date') {
          return event.target[p.name].value.replaceAll('-', '')
        } else {
          paramsConditions.push({
            name: p.displayName,
            value: !event.target[p.name].value
              ? 'All'
              : event.target[p.name].value
          })
          return event.target[p.name].value
        }
      })

      let storedProc = parameters.reduce((acc, param, index) => {
        return (acc += index + 1 === params.length ? `?)` : `?,`)
      }, `call ${process.env.REACT_APP_DATABASE_NAME}.${this.state.currentReport.storedproc}(?,?,?,?,`)

      if(parameters.length === 0) {
          storedProc = storedProc.slice(0,-1).concat(')')
      }
      let fetchData = {
        jsonWebTok: this.props.fetchInitialData.credentials.user.jsonWebTok,
        report_name: this.state.currentReport.report_name,
        storedProc,
        params: [
          user.email,
          this.state.currentReport.report_name,
          uuidv4(),
          event.target.jobName.value,
          ...params
        ],
        // params: [user.clientId, user.uuid, currentReport.id, ...params],
        storedProcName: this.state.currentReport.storedproc
      }


      const resp = await LambdaFetch(
        'reports',
        'post',
        this.props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: 'run-report',
          ...fetchData
        }),
        '',
        this.props.fetchInitialData.credentials
      )

      if (resp.success) {
        this.setState({ isSubmitting: false, paramOpen: false })
        this.props.fetchInitialData.createSnack(
          'Successfully ran report',
          'success',
          3000
        )
      } else {
        this.setState({ isSubmitting: false, paramOpen: false })
        this.props.fetchInitialData.createSnack(
          'Running report. May take a few minutes to show up in print manager',
          'warning',
          5000
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  onTagsChange = (event, values, name) => {
    this.setState({ [name]: values })
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTableCell: {
          root: {
            backgroundColor: '#FFF'
          }
        }
      }
    })
  render () {
    const { reports, isLoading, currentReport, paramOpen } = this.state
    if (isLoading) {
      return <LoadingCircle />
    }
    const { classes } = this.props
    let tabs = []
    if (this.state.reports) {
      tabs = this.state.reports.forEach(report => {
        if (tabs.indexOf(report.category) === -1) {
          tabs.push(reports.category)
        }
      })
    }

    return (
      <div>
        {paramOpen && (
          <Dialog
            fullWidth
            open={paramOpen}
            // onClose={this.handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <form onSubmit={this.runReport}>
              <DialogTitle>{currentReport.report_name}</DialogTitle>
              <DialogContent>
                <Grid container justify='flex-start' spacing={2}>
                <Grid  item xs={12} sm={12} md={12}>

                <TextField
                            id={'jobName'}
                            required={true}
                            label={'Job Name'}
                            variant='filled'
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <span />
                                </InputAdornment>
                              )
                            }}
                          />
</Grid>

                  {JSON.parse(currentReport.parameters).map((p, index) => {
                    if (p.fieldType === 'dynamicList') {
                      return (
                        <Grid key={index} item xs={12} sm={12} md={12}>
                          <Autocomplete
                            required
                            autoHighlight
                            autoSelect
                            openOnFocus
                            defaultValue={this.state.dropdownData[p.name][0]}
                            getOptionLabel={option => option.display}
                            onChange={(event, values) =>
                              this.onTagsChange(event, values, p.name)
                            }
                            options={
                              this.state.dropdownData[p.name]
                                ? this.state.dropdownData[p.name]
                                : []
                            }
                            renderInput={params => (
                              <TextField
                                required
                                {...params}
                                label={p.displayName}
                                variant='filled'
                              />
                            )}
                          />
                        </Grid>
                      )
                    } else if (p.fieldType === 'finiteList') {
                      return (
                        <Grid key={index} item xs={12} sm={12} md={12}>
                          <Autocomplete
                            required
                            getOptionLabel={option => option}
                            id={p.name}
                            options={p.list ? p.list.split(',') : []}
                            renderInput={params => (
                              <TextField
                                required
                                {...params}
                                label={p.displayName}
                                variant='filled'
                              />
                            )}
                          />
                        </Grid>
                      )
                    } else {
                      return (
                        <Grid key={index} item xs={12} sm={12} md={12}>
                          <TextField
                            id={p.name}
                            type={p.fieldType}
                            required={p.isRequired === 'True' || p.isRequired}
                            label={p.displayName}
                            variant='filled'
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <span />
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                      )
                    }
                  })}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose}>Close</Button>

                <LoadingButton
                  label='Confirm'
                  isLoading={this.state.isSubmitting}
                  color='primaryVLButton'
                  buttonType='submit'
                />
              </DialogActions>
            </form>
          </Dialog>
        )}
        <div style={{ margin: '1rem 0'}}>
          <Paper style={{ margin: 'auto', maxWidth: '900px' }}>
            <div className={classes.root}>
              <AntTabsVert
                color={this.props.fetchInitialData.credentials.primaryAppColor}
                orientation='vertical'
                variant='scrollable'
                value={this.state.tabValue}
                onChange={(event, newValue) =>
                  this.setState({ tabValue: newValue })
                }
                aria-label='Vertical tabs example'
                style={{ width: '120px' }}
              >
                {this.state.tabs.map((tab, index) => {
                  return (
                    <AntTab
                      color={
                        this.props.fetchInitialData.credentials.primaryAppColor
                      }
                      key={index}
                      label={tab}
                      value={index}
                    />
                  )
                })}
              </AntTabsVert>
              {this.state.tabs.map((tab, index) => {
                return (
                  <TabPanel
                    key={index}
                    style={{
                      paddingTop: 0,
                      paddingLeft: 0,
                      paddingRight: '0.5rem',
                      width: '100%'
                    }}
                    value={this.state.tabValue}
                    index={index}
                  >
                    <List
                      style={{ width: '100%' }}
                      dense
                      aria-label='main mailbox folders'
                    >
                      {reports
                        .filter(report => report.category === tab)
                        .sort((a,b) => a.report_name - b.report_name)
                        .map((row, index) => {
                          return (
                            <ListItem
                              button
                              style={{ minWidth: '100%' }}
                              onClick={() => this.handleCurrentReport(row)}
                              key={index}
                            >
                              <ListItemText
                                primary={row.report_name}
                                secondary={row.description}
                              />
                            </ListItem>
                          )
                        })}
                    </List>
                  </TabPanel>
                )
              })}
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withSnackbar(Reports))

function TabPanel (props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ paddingLeft: '1rem' }}>{children}</Box>}
    </div>
  )
}

function calculateSort (cols) {
  return (data, colIndex, order) => {
    const dataType = cols[colIndex].dataType
    if (dataType === 'currency' || dataType === 'number') {
      return data.sort((a, b) => {
        return (
          (parseFloat(a.data[colIndex]) < parseFloat(b.data[colIndex])
            ? -1
            : 1) * (order === 'desc' ? 1 : -1)
        )
      })
    }
    if (dataType === 'date') {
      return data.sort((a, b) => {
        return (
          (new Date(a.data[colIndex]) < new Date(b.data[colIndex]) ? -1 : 1) *
          (order === 'desc' ? 1 : -1)
        )
      })
    }
    return data.sort((a, b) => {
      return (
        (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
        (order === 'desc' ? 1 : -1)
      )
    })
  }
}

function customFooter (cols, tableData) {
  let showFooter = false
  let indexOfFirstTotal = 0

  const totalRow = cols.map((c, index) => {
    if (c.total) {
      indexOfFirstTotal = indexOfFirstTotal === 0 ? index : indexOfFirstTotal
      showFooter = true
      const total = tableData.reduce((tot, cur) => {
        tot += parseFloat(cur[index] ? cur[index] : 0)
        return tot
      }, 0)
      return (
        <TableCell key={index} style={{ color: 'black', fontSize: '0.85rem' }}>
          {formatter.format(total)}
        </TableCell>
      )
    } else {
      return <TableCell key={index}></TableCell>
    }
  })

  totalRow[indexOfFirstTotal - 1] = (
    <TableCell key='total' style={{ color: 'black', fontSize: '0.85rem' }}>
      Total:
    </TableCell>
  )

  if (showFooter) {
    return (
      <TableFooter>
        <TableRow>{totalRow}</TableRow>
      </TableFooter>
    )
  }
}
