import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  TableFooter,
  TableRow,
  TableCell,
  Paper,
  InputAdornment,
  Button
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import { trialBalanceCols } from '../constants/GlCols'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import DialogTable from '../components/DialogTable'
import LoadingCircle from '../components/common/LoadingCircle'
import { smallScreenWidth } from '../constants/AppConstants'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import clsx from 'clsx'
import { currencyTextFormat } from '../utils/formatter'

const _ = require('lodash')

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  footerCell: {
    backgroundColor: '#F5F5F5',
    borderBottom: 'none',
    zIndex: 999
  },
  stickyFooterCell: {
    position: 'sticky',
    bottom: '-1px',
    zIndex: 100
  },
  rightTableHead: {
    '& > span': {
      justifyContent: 'flex-end',
      marginRight: '0'
    }
  }
}))

const startAdornment = (
  <>
    <InputAdornment position='start'>
      <div></div>
    </InputAdornment>
  </>
)
const defaultHeaders = {
  fiscalYear: '',
  acctPeriod: '',
  active: 1
}

export default function TrialBalances (props) {
  const [state, setstate] = useState({
    options: null,
    isLoading: false,
    data: null,
    fetched: false,
    isServerSide: false
  })
  const [headers, setHeaders] = useState(defaultHeaders)
  const [clearHeader, setClearHeaders] = React.useState(false)

  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  const handleChange = e => {
    const { name, value } = e.target
    e.preventDefault()
    setHeaders({ ...headers, [name]: value })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    fetchTrialData(headers)
  }

  const fetchTrialData = async params => {
    setstate({ ...state, isLoading: true })

    try {
      const resp = await LambdaFetch(
        'trial-balance',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-trial-balances',
          ...params
        }),
        '',
        credentials
      )

      setstate({
        ...state,
        data: resp.data.trialBalances,
        isLoading: false
      })
    } catch (e) {
      console.log(e)
      setstate({ ...state, isLoading: false })
    }
  }
  const options = getTableOptions({
    cols: trialBalanceCols,
    fileName: `GL_Trial_Balance`
  })

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true
  })

  const customBodyFoot = opts => {
    let tots = opts.data.reduce(
      (acc, cur) => {
        acc.beg += parseFloat(cur.data[9].props.value)
        acc.debit += parseFloat(cur.data[10].props.value)
        acc.credit += parseFloat(cur.data[11].props.value)
        acc.end += parseFloat(cur.data[12].props.value)
        return acc
      },
      { beg: 0, debit: 0, credit: 0, end: 0 }
    )

    return (
      <TableFooter className={footerClasses}>
        <TableRow
          style={{ borderTop: 'solid black 1px' }}
          className={footerClasses}
        >
          <TableCell
            size='small'
            colSpan={8}
            className={footerClasses}
          ></TableCell>
          <TableCell
            className={footerClasses}
            size='small'
            align='right'
            style={{ color: 'black', fontSize: '14px', fontWeight: 600 }}
            colSpan={1}
          >
            Total:{' '}
          </TableCell>

          <TableCell
            className={footerClasses}
            size='small'
            align='right'
            style={{ color: 'black', fontSize: '14px', fontWeight: 600 }}
            colSpan={1}
          >
            {`${currencyTextFormat(tots.beg)}`}
          </TableCell>
          <TableCell
            className={footerClasses}
            size='small'
            align='right'
            style={{ color: 'black', fontSize: '14px', fontWeight: 600 }}
            colSpan={1}
          >
            {`${currencyTextFormat(tots.debit)}`}
          </TableCell>
          <TableCell
            className={footerClasses}
            size='small'
            align='right'
            style={{ color: 'black', fontSize: '14px', fontWeight: 600 }}
            colSpan={1}
          >
            {`${currencyTextFormat(tots.credit)}`}
          </TableCell>
          <TableCell
            className={footerClasses}
            size='small'
            align='right'
            style={{ color: 'black', fontSize: '14px', fontWeight: 600 }}
            colSpan={1}
          >
            {`${currencyTextFormat(tots.end)}`}
          </TableCell>
        </TableRow>
      </TableFooter>
    )
  }

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        GL Trial Balance
      </Typography>
      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-end'
              spacing={3}
            >
              {[
                {
                  id: 'fiscalYear',
                  dataField: 'FISCAL_YEAR',
                  label: 'Year',
                  dateType: 'number',
                  isRequired: true
                },
                {
                  id: 'acctPeriod',
                  label: 'Account Period',
                  dataField: 'ACCT_PERIOD',
                  dataType: 'number',
                  isRequired: true
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <ServerSideAutoCompletev2
                      disabled={false}
                      required={true}
                      id={field.id}
                      width='125px'
                      label={field.label}
                      key={clearHeader}
                      dontAutoHighlight
                      dontOpenOnFocus
                      accessToken={credentials.user.accessToken}
                      error={`No ${field.label} Found`}
                      variant='standard'
                      handleChange={(id, hit) => {
                        setHeaders({ ...headers, [field.id]: id })
                      }}
                      credentials={credentials}
                      apiResource='trial-balance'
                      secondaryParams={{
                        limit: 15,
                        lookupField: field.dataField,
                        action: 'autocomplete-trial-balance',
                        ...headers
                      }}
                    />
                  </Grid>
                )
              })}

              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={headers.active}
                      onChange={e => {
                        setHeaders({
                          ...headers,
                          active: e.target.checked ? 1 : 0
                        })
                      }}
                      name='active'
                      color='primary'
                    />
                  }
                  label='Active'
                />
              </Grid>
            </Grid>

            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                disabled={!(headers.fiscalYear && headers.acctPeriod)}
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
              {!!(headers.fiscalYear || headers.acctPeriod) && (
                <Button
                  onClick={() => {
                    setClearHeaders(!clearHeader)
                    setHeaders(defaultHeaders)
                  }}
                  style={{ marginLeft: '0.5rem' }}
                >
                  {' '}
                  Clear
                </Button>
              )}
            </div>
          </form>
        </Paper>

        {state.data && (
          <div style={{ marginTop: '1rem ' }}>
            <TableMui
              cols={trialBalanceCols}
              data={state.data}
              options={{
                ...options,
                customTableBodyFooterRender: customBodyFoot
              }}
              title=''
            />
          </div>
        )}
      </div>
    </div>
  )
}
