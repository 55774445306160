import {currencyFormat, noWrapFormat,dateFormat} from '../utils/formatter';

export const testCols = [
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCT_UNIT',
    label: 'Account Unit',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCOUNT',
    label: 'Account',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCT_ND',
    label: 'Account ND',
    options: {
      filter: true,
      sort: true,
    },
  }
];


export const glChartCols = [
  {
    name: 'CHART_SECTION',
    label: 'Section',
    options: {
      filter: true,
      sort: true,
    },
  },
{
    name: 'SEQ_NUMBER',
    label: 'Number',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'DEPTH',
    label: 'Depth',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'SUMMARY_ACCT',
    label: 'Summary',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCOUNT_DESC',
    label: 'Description',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'ACCOUNT',
    label: 'Account',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'SUB_ACCOUNT',
    label: 'Sub Account',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCOUNT_DESC',
    label: 'Account Description',
    options: {
      filter: true,
      sort: true,
    },
  }
];

export const budgetHeaderCols = [
  {
    name: 'FISCAL_YEAR',
    label: 'Year',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'BUDGET_NBR',
    label: 'Budget',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'MAINT_DATE',
    label: 'Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'DESCRIPTION',
    label: 'Description',
    options: {
      filter: false,
      sort: false,
    },
  },
 
]

export const budgetCols = [
  {
    name: 'PRD',
    label: 'PRD',
    options: {
      filter: false,
      sort: false,
    },
  },

  {
    name: 'AMOUNT',
    label: 'Amount',
    options: {
      customBodyRender: currencyFormat,
      filter: false,
      sort: true,
    },
  },
    {
    name: 'UNITS',
    label: 'Units',
    options: {
      filter: false,
      sort: true,
    },
  },
    {
    name: 'FACTOR',
    label: 'Factor',
    options: {
      filter: true,
      sort: true,
    },
  },
    {
    name: 'RATE',
    label: 'Rate',
    options: {
      filter: true,
      sort: true,
    }
    }
]

export const glTransCols = [
  {
    name: 'JE_NUMBER',
    label: 'JE Number',
    options: {
      customBodyRender: noWrapFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'LINE_NBR',
    label: 'Line',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCT_UNIT',
    label: 'Account Unit',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'UNIT_DESCRIPTION',
    label: 'Unit Desc',
    options: {
      display: false,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCOUNT',
    label: 'Account',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCOUNT_DESC',
    label: 'Account Desc',
    options: {
      display: false,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'DESCRIPTION',
    label: 'Description',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'UNITS_AMOUNT',
    label: 'Units',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'POSTING_DATE',
    label: 'Posting Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'TRAN_AMOUNT',
    label: 'Tran Amount',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACTIVITY_LINK',
    label: 'Activity',
    options: {
      download: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'GTMXVALUE',
    label: 'Attributes',
    options: {
      download: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: 'MORE',
    label: 'More Info',
    options: {
      download: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: 'SOURCE_CODE',
    label: 'Source Code',
    options: {
      display: false,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'REFERENCE',
    label: 'Reference',
    options: {
      display: false,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'BASE_AMOUNT',
    label: 'Base Amount',
    options: {
      customBodyRender: currencyFormat,
      display: false,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'AUTO_REV',
    label: 'Auto Rev',
    options: {
      display: false,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACTIVITY',
    label: 'Activity',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: 'ACCT_CATEGORY',
    label: 'Category',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: 'ActCatDescription',
    label: 'Catergory Description',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: 'ActDescription',
    label: 'Description',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  }
]

export const moreGlTansCols = [
   {
    name: 'TO_COMPANYY',
    label: 'To Company',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'UNIT_DESCRIPTION',
    label: 'Acct Unit Desc',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCOUNT_DESC',
    label: 'Acct Desc',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'SOURCE_CODE',
    label: 'Source Code',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'REFERENCE',
    label: 'Reference',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'BASE_AMOUNT',
    label: 'Base Amount',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'AUTO_REV',
    label: 'Auto Rev',
    options: {
      filter: true,
      sort: true,
    },
  }
]


export const glActivityCols = [
  {
    name: 'ACTIVITY',
    label: 'Activity',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'ActDescription',
    label: 'Description',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'ACCT_CATEGORY',
    label: 'Category',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'ActCatDescription',
    label: 'Catergory Description',
    options: {
      filter: false,
      sort: false,
    },
  }

]

export const attribCols = [
  // {
  //   name: 'OBJ_ID',
  //   label: 'Obj ID',
  //   options: {
  //     filter: true,
  //     sort: true,
  //   },
  // },
  {
    name: 'MATRIX_CAT',
    label: 'Attribute',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'MX_VALUE',
    label: 'Value',
    options: {
      filter: true,
      sort: true,
    },
  }
]

export const trialBalanceCols = [
  {
    name: 'COMP',
    label: 'Co',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'CHART',
    label: 'Chart',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'FY',
    label: 'Year',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'PRD',
    label: 'Prd',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCT_UNIT',
    label: 'Acct Unit',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCT',
    label: 'Acct',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'SUB_ACCT',
    label: 'Sub Acct',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'AU_DESC',
    label: 'AU',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ACCT_DESC',
    label: 'Description',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'BEG_BAL',
    label: 'Beg Bal',
    options: {
      setCellProps: value => {
        return {
          style: { textAlign: 'right' }
        }
      },
      customBodyRender: currencyFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'DEBITS',
    label: 'Debits',
    options: {
      showTotal: true,
      setCellProps: value => {
        return {
          style: { textAlign: 'right' }
        }
      },
      customBodyRender: currencyFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'CREDITS',
    label: 'Credits',
    options: {
      setCellProps: value => {
        return {
          style: { textAlign: 'right' }
        }
      },
      customBodyRender: currencyFormat,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'END_BAL',
    label: 'End Bal',
    options: {
      setCellProps: value => {
        return {
          style: { textAlign: 'right' }
        }
      },
      customBodyRender: currencyFormat,
      filter: true,
      sort: true,
    },
  },

];