import {
  currencyFormat,
  noWrapFormat,
  dateFormat
} from '../utils/formatter'

export const poDetailCols = [
  {
    name: 'LINE_NBR',
    label: '#',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ITEM_TYPE',
    label: 'Type',
    options: {
      customBodyRender: noWrapFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Item',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'MATCH_DTL_KEY',
    label: 'Description',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'QUANTITY',
    label: 'Qty',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ENT_BUY_UOM',
    label: 'UOM',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ENT_UNIT_CST',
    label: 'Unit Cost',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'EARLY_DL_DATE',
    label: 'Delivery Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'REC_QTY',
    label: 'Rec Qty',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'MATCHED_QTY',
    label: 'Match Qty',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'LOCATION',
    label: 'Loc',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'CLOSED_FL',
    label: 'Closed',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'EXTENDED_AMT',
    label: 'Ext Amt',
    options: {
      customBodyRender: currencyFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'VEN_ITEM',
    label: 'Ven Item',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SOURCE',
    label: 'Source',
    options: {
      filter: true,
      sort: true
    }
  },
]


export const poSourceLineCols = [
  {
    name: 'ORIGIN_CD',
    label: 'Origin',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DOCUMENT',
    label: 'Document',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SRC_LINE_NBR',
    label: 'Line',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'REQUESTER',
    label: 'Requester',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'REQ_LOCATION',
    label: 'Location',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'QUANTITY',
    label: 'Qty',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ENT_BUY_UOM',
    label: 'OUM',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'NAME',
    label: 'Name',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'EMAIL_ADDRESS',
    label: 'Email',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'COMPANY',
    label: 'Company',
    options: {
      filter: true,
      sort: true
    }
  }
]
