import {dateTimeFormat} from '../utils/formatter';

export const printManagerCols = [
  {
    name: 'id',
    label: 'id',
    options: {
      display: false,
      download: false,
    },
  },
  {
    name: 'document_link',
    label: 'document_link',
    options: {
      display: false,
      download: false,
    },
  },
  {
    name: 'username',
    label: 'Username',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'event_name',
    label: 'Job Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'created_on',
    label: 'Create Date',
    options: {
        customBodyRender: dateTimeFormat,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'document_name',
    label: 'Report',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'link',
    label: 'Link',
    options: {
      download: false,
      filter: false,
      sort: false,
    },
  },
];
