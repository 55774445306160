import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export default {
  display: function (msg,variant,time) {
    this.toast(msg, variant, time);
  },
  success: function (msg) {
    this.toast(msg, 'success');
  },
  toast: function (msg, variant, autoHideTime) {
    const Display = withSnackbar(({ message, enqueueSnackbar }) => {
      enqueueSnackbar(message, { variant, autoHideDuration: autoHideTime ?autoHideTime : 1000 });
      return null;
    });
    const mountPoint = document.getElementById('snackbarhelper');
    ReactDOM.render(
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Display message={msg} variant={variant} />
      </SnackbarProvider>,
      mountPoint
    );
  },
  persist: function (msg, variant) {
    const notistackRef = React.createRef();
    const onClickDismiss = (key) => () => {
      notistackRef.current.closeSnackbar(key);
    };

    const Display = withSnackbar(({ message, enqueueSnackbar }) => {
      enqueueSnackbar(message, {
        variant,
        persist: false,
        preventDuplicate: true
      });
      return null;
    });
    const mountPoint = document.getElementById('snackbarhelper');
    ReactDOM.render(
      <SnackbarProvider
        preventDuplicate
        maxSnack={1}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        ref={notistackRef}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <Close style={{ color: 'white' }} />
          </IconButton>
        )}
      >
        <Display message={msg} variant={variant} />
      </SnackbarProvider>,
      mountPoint
    );
  },
};
