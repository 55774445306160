import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  Button,
  Paper,
  InputAdornment
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import { budgetCols } from '../constants/GlCols'
import TableMui from '../components/TableMui'
import LoadingCircle from '../components/common/LoadingCircle'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'
import getTableOptions from '../constants/TableOptions'
import { smallScreenWidth } from '../constants/AppConstants';
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  }
}))

const startAdornment = (
  <>
    <InputAdornment position='start'>
      <div></div>
    </InputAdornment>
  </>
)
const defaultHeaders = {
  fiscalYear: '',
  company: '',
  budgetNumber: '',
  accountUnit: '',
  account: '',
  subAccount: ''
}
export default function Budget (props) {
  const [state, setstate] = useState({
    isLoading: false,
    data: null,
    headerOptions: [],
    fetched: false
  })
  const [headers, setHeaders] = useState(defaultHeaders)
  const [clearHeader, setClearHeaders] = React.useState(false)


  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  useEffect(() => {
    async function fetchMyAPI () {
      fetchData()
    }
    fetchMyAPI()
  }, [])

  const fetchData = async () => {
    try {
      const resp = await LambdaFetch(
        'budget',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-options'
        }),
        '',
        credentials
      )

      setstate({ ...state, headerOptions: resp.data.options, fetched: true })
    } catch (e) {
      console.log(e)
    }
  }
  const handleSubmit = async e => {
    try {
      e.preventDefault()
      setstate({ ...state, isLoading: true })

      const resp = await LambdaFetch(
        'budget',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-budget',
          ...headers
        }),
        '',
        credentials
      )

      setstate({ ...state, isLoading: false, data: resp.data.budget })
    } catch (e) {
      console.log(e)
    }
  }

  const handleChange = (name, value) => {
    setHeaders({ ...headers, [name]: value })
  }

  const options = getTableOptions({cols: budgetCols, fileName: 'Budget'})

  if (!state.fetched) {
    return <LoadingCircle />
  }

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth  && 'Dashboard'}

      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Budget (FB20.1)
      </Typography>
      
      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={3}
            >

              {[
                {
                  id: 'fiscalYear',
                  dataField: 'FISCAL_YEAR',
                  label: 'Year',
                  dateType: 'number',
                  isRequired: true
                },
                {
                  id: 'budgetNumber',
                  dataField: 'BUDGET_NBR',
                  label: 'Budget',
                  dateType: 'number',
                  isRequired: true
                },
                {
                  id: 'company',
                  dataField: 'COMPANY',
                  label: 'Company',
                  dateType: 'number',
                  isRequired: true
                },
                {
                  id: 'accountUnit',
                  dataField: 'ACCT_UNIT',
                  label: 'Account Unit',
                  dateType: 'number',
                  isRequired: true
                },
                {
                  id: 'account',
                  dataField: 'ACCOUNT',
                  label: 'Account',
                  dateType: 'number',
                  isRequired: true
                },
                {
                  id: 'subAccount',
                  dataField: 'SUB_ACCOUNT',
                  label: 'Sub Account',
                  dateType: 'number',
                  isRequired: true
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>

                  <ServerSideAutoCompletev2
                    disabled={false}
                    required={true}
                    id={field.id}
                    width='150px'
                    label={field.label}
                    key={clearHeader}
                    // dontAutoHighlight
                    dontOpenOnFocus 
                    accessToken={credentials.user.accessToken}
                    error={`No ${field.label} Found`}
                    variant='standard'
                    handleChange={(id, hit) => {
                      handleChange(field.id, id)
                    }}
                    credentials={credentials}
                    apiResource='budget'
                    secondaryParams={{
                      limit: 10,
                      lookupField: field.dataField,
                      action: 'autocomplete-budget',
                      ...headers
                    }}
                  />
                  </Grid>
                )
              })}


            </Grid>
            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
 {!!(headers.fiscalYear ||
                headers.budgetNumber ||
                headers.company ||
                headers.accountUnit ||
                headers.account ||
                headers.subAccount) && (
                <Button
                  onClick={() => {
                    setClearHeaders(!clearHeader)
                    setHeaders(defaultHeaders)
                  }}
                  style={{ marginLeft: '0.5rem' }}
                >
                  {' '}
                  Clear
                </Button>
              )}
            </div>
          </form>
        </Paper>

        {state.data && (
          <Grid
            item
            xs={12}
            style={{ position: 'relative', marginTop: '1rem' }}
          >
            <TableMui
              cols={budgetCols}
              data={state.data}
              options={{rowsPerPage: 20, ...options}}
              title=''
            />
          </Grid>
        )}
      </div>
    </div>
  )
}
